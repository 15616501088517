import providerChannelModule from '@/services/providerChannel';

export default {
  data() {
    return {
      visible: false,
      balance: [],
      fetchingData: true
    };
  },
  props: {
    channelType: String,
    channel: String
  },
  methods: {
    showPopup() {
      this.visible = true;
      this.fetchBalance();
    },

    async fetchBalance() {
      this.fetchingData = true;
      try {
        let result = await providerChannelModule.getChannelBalance(this.channelType, this);
        this.balance = result.data;
        this.fetchingData = false;
      } catch (err) {
        this.errorToast('Failed to fetch balance. Something went wrong.');
        this.fetchingData = false;
        this.reportError(err);
        console.error('Failed to fetch balance.', err);
      }
    },

    toggleVisiblity() {
      this.visible = !this.visible;
    }
  }
};
