<template>
  <el-dialog append-to-body="" :title="channel + ' Balance'" custom-class="shortLinkDialog" :visible.sync="visible" width="600px">
    <div class="dialogContent">
      <div v-if="fetchingData" class="loader" v-loading="true"></div>

      <el-table v-else :data="balance">
        <el-table-column prop="name" label="Id/Name"></el-table-column>
        <el-table-column prop="balance" label="Credits/Balance" width="180"></el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<style lang="scss" src="./channelBalance.scss"></style>

<script>
import channelBalance from './channelBalanceComponent';
export default channelBalance;
</script>
